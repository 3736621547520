import React, {useEffect} from 'react'
import {NavLink, useNavigate} from "react-router-dom";
import {useAuth} from "../Auth/auth";
import {useUserStore} from '../Stores/userStore';

const Navigation = () => {

    const auth = useAuth();
    let navigate = useNavigate();
    const isTokenValid = useUserStore(state => state.isTokenValid);

    async function navToProtected(path) {

        await auth.isSignedIn();

        if (isTokenValid() === true) {
            navigate(path);
        }
    }

    useEffect(() => {
        //console.log('reloading navbar')
    }, [isTokenValid])

    return (
        <div className="bg-theme-dark flex flex-row gap-2 pt-2 px-2 sticky top-0 z-50 shadow-xl justify-center">
            <NavLink to='/' className="bg-theme text-white px-6 py-3 w-24 text-center cursor-pointer rounded-t-md">
                Home
            </NavLink>
            {
                auth.tokenData?.permission_level >= 7 &&
                <>
                    <div
                        className="bg-theme text-white px-6 py-3 w-52 text-center cursor-pointer rounded-t-md hover:font-semibold"
                        onClick={() => navToProtected('/dashboard')}>
                        Feedback dashboard
                    </div>
                    <div
                        className="bg-theme text-white px-6 py-3 w-52 text-center cursor-pointer rounded-t-md hover:font-semibold"
                        onClick={() => navToProtected('/admin')}>
                        Admin panel
                    </div>
                </>
            }
            {
                !auth.tokenData?.token ?
                    <NavLink to='/login'
                             className="bg-theme text-white w-32 px-6 py-3 text-center text-center cursor-pointer rounded-t-md hover:font-semibold">
                        Sign in
                    </NavLink> :
                    <div
                        className="bg-theme text-white px-6 py-3 w-32 text-center cursor-pointer rounded-t-md hover:font-semibold place-end"
                        onClick={() => auth.signOut({onsuccess: navigate('/login', {replace: true})})}>
                        Sign out
                    </div>
            }
        </div>
    )
}

export default Navigation