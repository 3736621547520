import emailjs from '@emailjs/browser';

const service_id = process.env.REACT_APP_SERVICE_ID;
const template_id = process.env.REACT_APP_TEMPLATE_ID;
const public_key = process.env.REACT_APP_PUBLIC_KEY;

export const sendEmail = async (project_title, to_email, password) => {

    const templateParams = {
        project_title: project_title,
        to_email: to_email,
        password: password
    };

    console.log('sending...')

    await emailjs.send(service_id, template_id, templateParams, public_key)
        .then((result) => {
            console.log(result.text);
            alert("Signup email sent to : " + templateParams.to_email)
            return {
                message: `Email sent to ${to_email}`
            }
        }, (error) => {
            console.log(error.text);
            return {
                error: `Could not send email to ${to_email}`
            }
        });

    console.log('done sending')
};