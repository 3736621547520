import useStore from "../Stores/userStore"
import jwt_decode from "jwt-decode";

export const useAuth = () => {

    const [user, tokenData, setTokenData, clearUser, isTokenValid, setRefreshTokens] = useStore(state => [state.user, state.tokenData, state.setTokenData, state.clearUser, state.isTokenValid, state.setRefreshTokens]);

    const host = process.env.REACT_APP_SERVER_HOST;
    const port = process.env.REACT_APP_API_PORT;

    const signIn = (email, password) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                'email': email,
                'password': password
            })
        };

        return fetch(`${host}:${port}/api/auth/login`, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.error) {
                    return data;
                }

                try {
                    const decoded = jwt_decode(data.token);

                    setTokenData({
                        token: data.token,
                        id: decoded.id,
                        email: decoded.email,
                        permission_level: decoded.permission_level
                    });
                    return {...decoded, status: 200};
                } catch (err) {
                    return {
                        message: 'invalid token',
                        error: 'invalid token'
                    }
                }
            })
            .catch(error => {
                return {error: error};
            })
    };

    const signOut = (onSuccess, onError) => {

        clearUser();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        };

        return fetch(`${host}:${port}/api/auth/logout`, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.error) {
                    onError?.invoke();
                    return data;
                }
                onSuccess?.invoke();
                return data;
            })
            .catch(error => {
                onError?.invoke();
                return error;
            })
    };

    const isSignedIn = async () => {

        const tokenValid = isTokenValid();

        if (!tokenValid) {
            const refreshed = await tryRefreshToken();

            if (refreshed.error) {
                clearUser();
                return false;
            }

            return true;
        }

        return tokenValid;
    }

    function tryRefreshToken() {

        console.log('Access token expired, trying to refresh it')

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
        };

        return fetch(`${host}:${port}/api/auth/refresh_token`, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.error) {
                    return data;
                }
                try {
                    const decoded = jwt_decode(data.token);

                    setTokenData({
                        token: data.token,
                        id: decoded.id,
                        email: decoded.email,
                        permission_level: decoded.permission_level
                    });
                    return {...decoded, status: 200};
                } catch (err) {
                    return {
                        message: 'invalid token',
                        error: 'invalid token'
                    }
                }
            })
            .catch(error => {
                clearUser();
                return {
                    error: error
                };
            })
    }

    return {
        user: user,
        tokenData: tokenData,
        isSignedIn,
        signIn,
        signOut
    }
}