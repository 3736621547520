import React, { useState, useEffect } from 'react'
import shortcuts from '../Data/urls';
import PersonaShortcut from "../Components/PersonaShortcut";
import {ReactComponent as Logo} from './../Images/personunknown_logo_simplified.svg'

const Home = () => {

    const [emergencyOpen, setEmergencyOpen] = useState(false);
    const [img, setImg] = useState();
    
    const toggleArticyEmergency = () => {
        
        const newState = !emergencyOpen;
        setEmergencyOpen(newState);
    }
    
    useEffect(() => {
        if (emergencyOpen === true) {
            fetchRandomDog();
            const interval = setInterval(() => {
                fetchRandomDog();
            }, 4000);
            return () => clearInterval(interval);
        }
        else {
            setImg(null)
        }
        
    }, [emergencyOpen])
    
    const fetchRandomDog = async () => {
        
        const res = await fetch('https://placedog.net/640/480?random');
        const imageBlob = await res.blob();
        const imageObjectUrl = URL.createObjectURL(imageBlob);
        
        setImg(imageObjectUrl);
    }

    return (
        <div className="container p-4 sm:p-12 space-y-2 max-w-4xl mx-auto">
            <div className="bg-gray-200 rounded-sm text-left h-24 py-4 flex flex-row px-4 items-center space-x-6">
                {<Logo className="h-full w-1/6"/>}
                <p className="font-semibold text-2xl w-5/6 select-none">
                    Persona Hub Pagina
                </p>
                <div onClick={toggleArticyEmergency}
                    className="bg-red-800 hover:bg-red-600 rounded-lg px-2 py-2 text-sm text-white text-center font-bold cursor-pointer border-2 border-red-500 shadow-md">
                    Articy Stress Emergency Button!
                </div>
            </div>
            {
                emergencyOpen === true && 
                    <div className="bg-gray-100 rounded-lg overflow-hidden shadow-lg">
                        <img src={img} alt="Loading..." className="object-cover h-full w-full text-center"/>
                    </div>
            }
            {
                shortcuts.map((shortcut, index) => {
                    return (
                        <PersonaShortcut key={index} shortcutData={shortcut} index={index}/>
                    )
                })
            }
        </div>
    )
}

export default Home