import React, {useEffect, useState} from 'react';

function useFetch({url, options, searchParams}) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const host = process.env.REACT_APP_SERVER_HOST;
    const port = process.env.REACT_APP_API_PORT;

    // suffix searchParams if supplied
    const fullUrl = `${host}:${port}${url}` + (searchParams != null ? ('?' + searchParams) : '')

    useEffect(() => {

        //console.log('fetching from :', fullUrl)

        //setLoading(true);
        setRefresh(false);

        fetch(fullUrl, options)
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.error)
                    setError(data);
                else
                    setData(data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                //setLoading(false);
            })
    }, [refresh])

    return {data, setRefresh, loading, error};
}

export default useFetch;