import React, {useEffect} from 'react';
import {useAuth} from "./Auth/auth";

import {Navigate, Route, Routes} from "react-router-dom";
import {useUserStore} from './Stores/userStore';

import Navigation from "./Components/Navigation";
import Home from "./Components/Home";
import Login from "./Components/Login";
import Admin from "./Components/Admin";
import FeedbackTable from "./Components/Tables/FeedbackTable";

function App() {

    const auth = useAuth();

    useEffect(() => {
        auth.isSignedIn();
    }, [])

    return (
        <div className="flex flex-col bg-gradient-to-br from-theme to-theme-dark min-h-screen overflow-hidden">

            <Navigation/>
            <div className="relative flex flex-grow pb-20">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route exact path="login" exact element={<Login/>}/>

                    <Route exact path="dashboard" element={
                        <ProtectedRoute levelNeeded={6} redirectPath="/">
                            <FeedbackTable/>
                        </ProtectedRoute>}
                    />

                    <Route exact path="admin" element={
                        <ProtectedRoute levelNeeded={6} redirectPath="/">
                            <Admin/>
                        </ProtectedRoute>}
                    />

                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>
            </div>
        </div>
    );
}

function ProtectedRoute({levelNeeded, redirectPath = '/login', children}) {

    const auth = useAuth();
    auth.isSignedIn();

    const [isTokenValid, tokenData] = useUserStore(state => [state.isTokenValid, state.tokenData]);

    console.log('checking')

    if (isTokenValid() === false) {
        return <Navigate to={redirectPath} replace/>
    }

    if (tokenData?.permission_level < levelNeeded) {
        return <Navigate to={redirectPath} replace/>
    }

    return children;
}

export default App;
