const urls = [
    {
        "name": "SZW GroupPlay",
        "url": "https://szwgroupplay.personunknown.com"
    },
    {
        "name": "Provincie Utrecht",
        "url": "https://provincieutrecht.personunknown.com"
    },
    {
        "name": "Module GP 07",
        "url": "https://modulegp07.personunknown.com"
    },
    {
        "name": "Kredietbank",
        "url": "https://kredietbank.personunknown.com"
    },
    {
        "name": "Inspecta 2",
        "url": "https://inspecta2.personunknown.com"
    },
    {
        "name": "Gemeente Groningen",
        "url": "https://gemeentegroningen.personunknown.com"
    },
    {
        "name": "Equipe",
        "url": "https://equipe.personunknown.com"
    },
    {
        "name": "Prototype",
        "url": "https://prototype.personunknown.com"
    },
    {
        "name": "Mentale Gezondheid",
        "url": "https://mentalegezondheid.personunknown.com"
    },
    {
        "name": "Toolbox",
        "url": "https://toolbox.personunknown.com"
    },
    {
        "name": "Persona Triage",
        "url": "https://triage.personunknown.com"
    },
    {
        "name": "Online Escaperoom",
        "url": "https://szw-gevaarlijkestoffen.personunknown.com/pilot"
    }
]

export default urls;
