import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useAuth} from "../../Auth/auth";
import {useNavigate} from "react-router-dom";
import UseFetch from '../../Hooks/useFetch'
import LoadingOverlay from "../LoadingOverlay";
import MaterialTable, {MTableEditRow} from "material-table";
import PatchedPagination from "../../Misc/PatchedPagination";
import tableIcons from "../../Data/tableIcons";
import {sendEmail} from "../../Data/emailJs";

const domainRegExp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;

const UsersTable = () => {

    const host = process.env.REACT_APP_SERVER_HOST;
    const port = process.env.REACT_APP_API_PORT;

    let navigate = useNavigate();
    const auth = useAuth();

    const [generatedPassword, setGeneratedPassword] = useState("");

    useEffect(async () => {

        const validToken = await auth.isSignedIn();
        if (!validToken)
            navigate('/', {replace: true});
    }, []);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${auth.tokenData.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    const {data, setRefresh, loading, error} = UseFetch({
        url: "/api/users/all",
        options: requestOptions,
    });
    const fetchedData = useMemo(() => [...data], [data]);

    if (loading || !data) return <div className="rounded-xl overflow-hidden shadow-lx"><LoadingOverlay/></div>

    async function deleteUser(email) {

        // user tries to set himself inactive
        if (email === auth.tokenData.email) {
            alert("You cannot delete yourself!");
            return;
        }

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${auth.tokenData.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        };
        fetch(`${host}:${port}/api/users`, requestOptions)
            .then(data => {
                setRefresh(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    async function addNewUser(newData) {

        alert(`password for user ${newData.email} is:\n\n${newData.password}\n\nPlease write this down somewhere as it will not be displayed further on!`);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth.tokenData.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: newData.email,
                password: newData.password,
                permission_level: newData.permission_level,
                active: newData.active
            })
        };

        fetch(`${host}:${port}/api/users`, requestOptions)
            .then(async data => {
                if (data.status === 200) {
                    setRefresh(true)
                    await sendEmail('Persona', newData.email, newData.password);
                    //return true;
                }
            })
            .catch(err => {
                console.log('error', err)
                //return false;
            })
    }

    async function updateUser(id, newValue) {

        // user tries to set himself inactive
        if (id === auth.tokenData.id && parseInt(newValue.active) === 0) {
            alert("You cannot set yourself inactive!");
            return;
        }

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${auth.tokenData.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user: {
                    id: id,
                    email: newValue.email,
                    active: newValue.active,
                    accessible_domains: newValue.accessible_domains,
                    permission_level: newValue.permission_level
                }
            })
        };

        fetch(`${host}:${port}/api/users`, requestOptions)
            .then(data => {
                setRefresh(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    function getRandomPassword(passwordLength) {
        let password = "";
        for (let i = 0; i < passwordLength; i++) {
            const randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }
        return password;
    }

    function changePw(e) {
        console.log(e.target.value)
        setGeneratedPassword(e.target.value);
    }

    return (
        <MaterialTable
            components={{
                Pagination: PatchedPagination,
            }}
            options={{
                exportButton: true,
                exportAllData: true,
                exportFileName: `PersonaProjects_${new Date(Date.now()).toLocaleString('nl')}`,

                actionsColumnIndex: -1,

                paging: true,
                pageSize: 15,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [10, 15, 20, 50],

                tableLayout: 'auto',
            }}
            icons={tableIcons}
            title="Users"
            columns={[
                {
                    title: 'ID', field: 'id',
                    cellStyle: {width: '0px',},
                    headerStyle: {width: '0px',},
                    editable: 'never'
                },
                {
                    title: 'Email',
                    field: 'email',
                    editable: 'always',
                    render: rowData => (
                        <p className="text-sm ">{rowData.email}</p>
                    ),
                },
                {
                    title: 'Password',
                    field: 'password',
                    editable: 'onAdd',
                    type: 'string',
                },
                {
                    title: 'Permission level',
                    field: 'permission_level',
                    cellStyle: {width: '200px',},
                    headerStyle: {width: '200px',},
                    type: 'numeric',
                    align: "center",
                    validate: rowData => rowData.permission_level > 0 && rowData.permission_level <= 7,
                },
                {
                    title: "Active",
                    field: 'active',
                    editable: 'always',
                    cellStyle: {width: '0px',},
                    headerStyle: {width: '0px',},
                    lookup: {0: 'inactive', 1: 'active'},
                    align: "center",
                    render: rowData => (
                        <div className={`${rowData.active === 0 ?
                            "bg-red-500" :
                            "bg-green-500"} text-white rounded-lg text-center py-1 px-3 font-semibold`}>{rowData.active === 0 ? "inactive" : "active"}</div>
                    ),
                },
                {
                    title: 'Times signed in',
                    field: 'times_signed_in',
                    cellStyle: {width: '80px',},
                    headerStyle: {width: '80px',},
                    align: "center",
                    editable: 'never',
                },
                {
                    title: 'Has access to domain ids | [1 id per line]',
                    field: 'accessible_domains',
                    editComponent: props => (
                        <textarea
                            value={props.value != null ? JSON.parse(props.value).domains.join('\n') : ""}
                            onChange={e => {
                                let updated = props.value != null ? JSON.parse(props.value) : {};
                                updated.domains = e.target.value.split('\n');
                                props.onChange(JSON.stringify(updated));
                            }}
                            className="h-32 text-sm w-full break-all"/>
                    ),
                    render: rowData => (
                        <p className="text-sm">{JSON.parse(rowData.accessible_domains).domains.join('\n')}</p>
                    ),
                    emptyValue: "none",
                    cellStyle: {width: '160px',},
                    headerStyle: {width: '160px',},
                    editable: 'always',
                },
                {
                    title: 'Last signed in',
                    field: 'last_signed_in',
                    render: rowData => (
                        <p>{new Date(rowData.created_at).toLocaleString('nl')}</p>
                    ),
                    emptyValue: "never",
                    align: "center",
                    cellStyle: {width: '200px',},
                    headerStyle: {width: '200px',},
                    editable: 'never',
                },
                {
                    title: 'Created at',
                    field: 'created_at',
                    render: rowData => (
                        <div className="">{new Date(rowData.created_at).toLocaleString('nl')}</div>
                    ),
                    cellStyle: {width: '200px',},
                    headerStyle: {width: '200px',},
                    editable: 'never',
                    hidden: true,
                },
                {
                    title: 'Last updated at',
                    field: 'updated_at',
                    render: rowData => (
                        <div className="">{new Date(rowData.updated_at).toLocaleString('nl')}</div>
                    ),
                    cellStyle: {width: '200px',},
                    headerStyle: {width: '200px',},
                    editable: 'never',
                    hidden: true,
                }
            ]}
            data={fetchedData}
            editable={{
                onRowAdd: newData =>
                    new Promise(async (resolve, reject) => {
                        await addNewUser(newData);
                        resolve();
                    }),

                onRowDelete: oldData =>
                    new Promise(async (resolve, reject) => {
                        await deleteUser(oldData.email);
                        resolve();
                    }),

                onRowUpdate: (newData, oldData) =>
                    new Promise(async (resolve, reject) => {
                        await updateUser(oldData.id, newData);
                        resolve();
                    }),
            }}
        />
    )
}

export default UsersTable
