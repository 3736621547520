import React from 'react'
import {FaRegCopy} from 'react-icons/fa';
import copyUrlToClipboard from "../Misc/Utilities";

const PersonaShortcut = ({shortcutData, index}) => {

    const openUrlInNewTab = () => {
        window.open(shortcutData.url);
        //fetchLastUpdatedDate(shortcutData.url);
    };

    const fetchLastUpdatedDate = (url) => {

        fetch(url).then(function (response) {
            // for each response header, log an array with header name as key
            console.log(...response.headers);
            response.blob().then(function (myBlob) {
                console.log(response);
            });
        });
    }

    return (
        <div className="flex mx-auto items-center bg-theme-dark text-gray-50 px-3 py-0 rounded-sm shadow-sm
                        transition cursor-pointer duration-100 hover:shadow-md hover:bg-theme">
            <div className="flex-row sm:flex-col flex-grow mr-5 py-2" onClick={openUrlInNewTab}>
                <p className="flex-grow font-semibold text-lg select-none">
                    {(index + 1) + " - " + shortcutData.name}
                </p>
                <p className="text-sm underline text-gray-50 text-opacity-70">
                    {shortcutData.url}
                </p>
            </div>
            <div className="w-10 p-0 m-0 h-full flex-none">
                <FaRegCopy className="hover:text-theme-dark h-full w-full"
                           onClick={() => copyUrlToClipboard(shortcutData.url)}/>
            </div>
        </div>
    )
}

export default PersonaShortcut