import React, {useEffect, useMemo} from 'react'
import {useUserStore} from "../../Stores/userStore";
import UseFetch from "../../Hooks/useFetch";
import {useAuth} from "../../Auth/auth";

import MaterialTable from 'material-table';
import tableIcons from '../../Data/tableIcons'
import PatchedPagination from "../../Misc/PatchedPagination";
import LoadingOverlay from "../LoadingOverlay";
import {useNavigate} from "react-router-dom";

// https://github.com/google/diff-match-patch/wiki/Language:-JavaScript
import {diff_match_patch} from "diff-match-patch";
import copyUrlToClipboard from "../../Misc/Utilities";
import {FaRegCopy} from "react-icons/fa";

const FeedbackTable = () => {

    let navigate = useNavigate();
    const user = useUserStore(state => state.user);
    const auth = useAuth();

    useEffect(async () => {

        const validToken = await auth.isSignedIn();
        if (!validToken)
            navigate('/', {replace: true});

    }, [])

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${auth.tokenData.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    const searchParams = new URLSearchParams({
        email: user.email,
        match_phase: false
    });
    const {data, setRefresh, loading, error} = UseFetch({
        url: "/api/feedback/all",
        options: requestOptions,
        searchParams: searchParams,
    });
    const fetchedData = useMemo(() => [...data], [data]);

    function styleDiffText(value) {
        if (value === 1)
            return 'text-red-500 font-semibold line-through'
        else if (value === -1)
            return 'text-green-500 font-semibold underline'

        return 'text-black'
    }

    if (loading || !data) return <LoadingOverlay/>

    async function deleteFeedback(feedback) {

        const feedbackId = feedback.id;

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${auth.tokenData.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                feedback_id: feedbackId
            })
        };
        const host = process.env.REACT_APP_SERVER_HOST;
        const port = process.env.REACT_APP_API_PORT;

        fetch(`${host}:${port}/api/feedback`, requestOptions)
            .then(data => {
                setRefresh(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    function toggleProcessedState(rowData) {
        let {id, node_index, processed} = rowData;
        processed = processed === 0 ? 1 : 0;

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${auth.tokenData.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id,
                node_index,
                processed
            })
        };
        const host = process.env.REACT_APP_SERVER_HOST;
        const port = process.env.REACT_APP_API_PORT;

        fetch(`${host}:${port}/api/feedback`, requestOptions)
            .then(data => {
                setRefresh(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div className="max-w-screen-2xl mx-auto p-2 container flex flex-col space-y-5 mt-8">
            <MaterialTable
                components={{
                    Pagination: PatchedPagination,
                }}
                options={{
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: `PersonaFeedback_${new Date(Date.now()).toLocaleString('nl')}`,
                    actionsColumnIndex: -1,

                    paging: true,
                    pageSize: 15,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 15, 20, 50],

                    grouping: true,
                    tableLayout: 'auto',
                }}
                icons={tableIcons}
                title="Persona feedback"
                columns={[
                    {
                        title: 'ID', field: 'id',
                        cellStyle: {width: '0px',},
                        headerStyle: {width: '0px',}
                    },
                    {
                        title: 'Project',
                        field: 'project_title',
                        defaultGroupOrder: 0
                    },
                    {
                        title: 'Phase',
                        field: 'project_phase'
                    },
                    {
                        title: 'Node index',
                        field: 'node_index',
                    },
                    {
                        title: 'Added by',
                        field: 'email',
                        export: false
                    },
                    {
                        title: 'Last updated at',
                        field: 'updated_at',
                        render: rowData => (
                            <div className="">{rowData.updated_at}</div>
                        ),
                        cellStyle: {width: '200px',},
                        headerStyle: {width: '200px',}
                    },
                    {
                        title: 'Edited text',
                        field: 'edited_text',
                        export: true,
                        hidden: true
                    },
                    {
                        title: 'Comments',
                        field: 'comments',
                        render: rowData => (
                            <div
                                className={`${rowData.comments ? "bg-green-500" : "bg-red-500"} ml-7 w-4 h-4 rounded-full capitalize text-center`}/>
                        ),
                        cellStyle: {width: '0px',},
                        headerStyle: {width: '0px',}
                    },
                    {
                        title: 'Processed',
                        field: 'processed',
                        lookup: {0: 'No', 1: 'Yes'},
                        render: rowData => (
                            <div onClick={() => toggleProcessedState(rowData)}
                                 className={`${rowData.processed ? "bg-green-500" : "bg-red-500"} ml-2 text-white rounded-lg h-8 w-14 capitalize text-center flex flex-row shadow-md cursor-pointer`}>
                                <p className="m-auto">{rowData.processed ? "yes" : "no"}</p>
                            </div>
                        ),
                        cellStyle: {width: '0px',},
                        headerStyle: {width: '0px',}
                    }
                ]}
                data={fetchedData}
                detailPanel={rowData => {

                    // https://github.com/google/diff-match-patch/wiki/Language:-JavaScript
                    const dmp = new diff_match_patch();
                    const diff = dmp.diff_main(rowData.edited_text, JSON.parse(rowData.original_fragment).Properties.Text);
                    diff.length > 1 && dmp.diff_cleanupSemantic(diff);

                    return (
                        <div className="flex flex-col space-y-5 px-12 py-5 bg-gray-200">
                            <div className="flex flex row mr-5 border-b-2">
                                <div className="w-1/6 flex-none flex flex-row font-semibold items-center">
                                    <p className="flex-grow">Comments</p>
                                    <FaRegCopy
                                        className="hover:opacity-70 active:opacity-100 flex-none opacity-40 ml-2 mr-5 h-6 w-6"
                                        onClick={() => copyUrlToClipboard(rowData.comments)}/>
                                </div>
                                <p className="w-5/6">{rowData.comments || 'n/a'}</p>
                            </div>
                            <div className="flex flex row mr-5 border-b-2">
                                <div className="w-1/6 flex-none flex flex-row font-semibold items-center">
                                    <p className="flex-grow">Resulting text</p>
                                    <FaRegCopy
                                        className="hover:opacity-70 active:opacity-100 flex-none opacity-40 ml-2 mr-5 h-6 w-6"
                                        onClick={() => copyUrlToClipboard(rowData.edited_text)}/>
                                </div>
                                <div className="w-5/6">
                                    <div>{rowData.edited_text}</div>
                                </div>
                            </div>
                            {
                                diff.length > 1 &&
                                <div className="flex flex row mr-5 border-b-2">
                                    <p className="w-1/6 flex-none font-semibold">Text Changes</p>
                                    <div className="w-5/6">
                                        {
                                            diff.map((item, key) => {
                                                return (
                                                    <span className={`${styleDiffText(item[0])}`}>{item[1]}</span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            <details className="flex flex row mr-5">
                                <summary className="w-1/6 flex-none font-semibold">Original fragment</summary>
                                <div
                                    className="2-5/6 whitespace-pre">{JSON.stringify(JSON.parse(rowData.original_fragment), null, 4)}</div>
                            </details>
                        </div>
                    )
                }}
                editable={{
                    onRowDelete: oldData =>
                        new Promise(async (resolve, reject) => {
                            await deleteFeedback(oldData);
                            resolve();
                        }),
                }}
            />
        </div>
    )
}

export default React.memo(FeedbackTable)
