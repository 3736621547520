import React, {useCallback, useEffect, useState} from 'react'
import { useDropzone } from "react-dropzone";
import { cleanJsonData } from "../Misc/CleanJson";
import cleanerJson from '../Data/cleanArticyJson.json';

const InfoPanel = () => {

    const [files, setFiles] = useState([]);
    const [data, setData] = useState(null);
    
    const [minify, setMinify] = useState(false);
    
    const [downloadHref, setDownloadHref] = useState(null);
    
    const [keysToRemove, setKeysToRemove] = useState("");
    
    useEffect(() => {
        let commaSeparated = "";
        for (let i = 0; i < cleanerJson.keysToRemove.length; i++) {
            commaSeparated += cleanerJson.keysToRemove[i];
            if (i < cleanerJson.keysToRemove.length - 1)
                commaSeparated += ',';
        }
        setKeysToRemove(commaSeparated);
    }, [])

    useEffect(() => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);
    
    useEffect(() => {
        if (data != null) {
            const fileName = "data";
            const blob = new Blob([data.cleanedData], { type: "application/json" });
            const href = URL.createObjectURL(blob);
            
            data.cleanedFileSize = blob.size;
            data.savingsPercentage = ((data.cleanedFileSize / data.originalFile.size) * 100.0).toFixed(1);

            setDownloadHref(href);
        }
        else {
            URL.revokeObjectURL(downloadHref);
            setDownloadHref(null);
        }
        
    }, [data])

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: {
            'application/json': ['.json']
        },
        maxFiles: 1,
        onDrop: acceptedFiles => {
            handleUploadedJson(acceptedFiles);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        }
    })

    const handleUploadedJson = async (acceptedFiles) => {

        if (acceptedFiles.length <= 0)
            return;

        setData(null);
        
        for (let i = 0; i < acceptedFiles.length; i++) {
            try
            {
                const response = await fetch(URL.createObjectURL(acceptedFiles[i]));
                const data = await response.json();

                const cleanedData = cleanJsonData(data, minify, keysToRemove, acceptedFiles[i]);
                setData(cleanedData);
            }
            catch (error)
            {
                setData(null);
                console.log(error);
            }
        }
    }

    const RemoveFiles = () => {
        setFiles([]);
        setData(null);
    }

    return (
        <div className="flex flex-col sm:flex-row gap-5">

            <div className="bg-white w-full sm:w-2/3 px-5 py-5 shadow-lg rounded-md flex flex-col space-y-3">

                <p className="text-xl font-semibold">Minify your jsons here!</p>
                
                <div {...getRootProps({className: 'dropzone cursor-pointer bg-theme text-white px-6 py-3 rounded-md shadow text-center hover:bg-theme-dark hover:shadow-md'})}>
                    <input {...getInputProps()} />
                    <p><b>Drag a json file onto here</b>, or <b>click</b> to upload a json file</p>
                </div>
                
                <div className="bg-transparent shadow-lg flex flex-row items-center rounded-md">
                    <p className="grow ml-3">Uploaded file: {files.length <= 0 ? "none" : files[0].name}</p>
                    <div className="shrink bg-theme hover:bg-theme-dark text-white rounded-r-md cursor-pointer py-3 px-4" onClick={RemoveFiles}>Remove</div>
                </div>

                {
                    fileRejections.length > 0 &&
                    <div className="bg-transparent shadow-lg grow rounded-md text-center flex flex-row justify-center items-center">
                        <p>Too many files!</p>
                    </div>
                }
                
                <div className="bg-transparent shadow-lg flex flex-row grow rounded-md">
                    <div className="w-3/8 p-3 flex flex-col gap-3">
                        <p>File size: {Math.round(files[0]?.size / 1024)}KB</p>
                        <p>Lines: {data?.originalLineCount}</p>

                        <label className="text-sm opacity-70">Keys that will be removed from the json (comma separated)</label>
                        <textarea name="Text1" cols="40" rows="5" value={keysToRemove} onChange={(e) => setKeysToRemove(e.target.value)}/>
                        
                        <div className="flex flex-row items-center">
                            <input
                                type="checkbox"
                                className="w-5 h-5 rounded border-theme-dark focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 "
                                onChange={(e) => setMinify(e.target.checked)} />
                                <label htmlFor="default-checkbox" className="ml-2 text-sm">
                                    Minify (file becomes unreadable by humans!)
                                </label>
                        </div>
                    </div>

                    {
                        data &&
                        <div className="w-24 p-3 justify-center items-center flex flex-row">
                            <p className="text-center text-3xl py-2 grow bg-theme rounded-md text-white hover:bg-theme-dark cursor-pointer"
                               onClick={() => handleUploadedJson(files)}>
                                -->
                            </p>
                        </div>
                    }
                    {
                        data &&
                        <div className="w-3/8 p-3 flex flex-col gap-3">
                            <p>File size: {Math.round(data?.cleanedFileSize / 1024)}KB</p>
                            <p>Lines: {data?.cleanedLineCount} (removed {data?.originalLineCount-data?.cleanedLineCount} lines)</p>
                            <p className="grow">That is {data?.savingsPercentage}% of the original file size</p>
                            
                            <a type="button" href={downloadHref} download="data.json"
                                className="bg-theme text-white text-center p-2 rounded-sm">
                                Download Json
                            </a>
                        </div>
                    }

                </div>
                
            </div>
            
            <div className="bg-white w-full sm:w-1/3 px-5 py-5 shadow-lg rounded-md">
                <p className="text-xl font-semibold">Permission level definitions</p>
                <p className="text-base">Each role has its associated permission and everything below it.</p>
                <p className="text-base">Normal users will generally be of permission level 3.</p>
                <table className="w-full">
                    <tbody>
                        <tr className="odd:bg-white even:bg-gray-100">
                            <th className="text-left w-1/6">Number</th>
                            <th className="text-left w-5/6">Definition</th>
                        </tr>
                        <tr className="odd:bg-white even:bg-gray-100">
                            <td>1</td>
                            <td>Can login</td>
                        </tr>
                        <tr className="odd:bg-white even:bg-gray-100">
                            <td>2</td>
                            <td>Can view own feedback</td>
                        </tr>
                        <tr className="odd:bg-white even:bg-gray-100">
                            <td>3</td>
                            <td>Can add/update/delete own feedback</td>
                        </tr>
                        <tr className="odd:bg-white even:bg-gray-100">
                            <td>4</td>
                            <td>Can view all feedback</td>
                        </tr>
                        <tr className="odd:bg-white even:bg-gray-100">
                            <td>5</td>
                            <td>Can add/update/delete all feedback</td>
                        </tr>
                        <tr className="odd:bg-white even:bg-gray-100">
                            <td>6</td>
                            <td>Can view all users</td>
                        </tr>
                        <tr className="odd:bg-white even:bg-gray-100">
                            <td>7</td>
                            <td>Can add/update/delete users</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default InfoPanel