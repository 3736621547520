import React, {useState} from 'react';
import {useUserStore} from "../Stores/userStore";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../Auth/auth";

const Login = () => {

    let navigate = useNavigate();
    const [isTokenValid, error, setError, clearError] = useUserStore(state => [state.isTokenValid, state.error, state.setError, state.clearError]);

    const apiPort = process.env.REACT_APP_API_PORT;

    const [inputEmail, setInputEmail] = useState("admin@personunknown.com");
    const [inputPassword, setInputPassword] = useState("persona123!");

    const auth = useAuth();

    function tryLogin(e) {
        e.preventDefault();
        clearError();

        auth.signIn(inputEmail, inputPassword)
            .then((response) => {

                if (response.status !== 200 || response.error) {
                    setError(response.error.toString());
                    return;
                }

                if (isTokenValid())
                    navigate('/dashboard', {replace: true});
                else {
                    setError(response)
                }
            })
            .catch(err => {

            })
            .finally(() => {
                setInputEmail("");
                setInputPassword("");
            })
    }

    function fillFields(e, params) {
        e.preventDefault();
        const {email, password} = params;

        setInputEmail(email);
        setInputPassword(password);
    }

    return (
        <div className="bg-white max-w-screen-sm mx-auto pt-10 pb-10 px-8 flex container">
            <form className="flex flex-col space-y-2 mx-auto">
                <div className="text-2xl text-center mb-5">Login page</div>

                <div className="flex flex-row items-center">
                    <label className='w-1/4 mr-5'>Email address</label>
                    <input className='w-3/4 rounded-lg'
                           key="input_email"
                           type="email"
                           placeholder="email@example.com"
                           value={inputEmail}
                           onChange={(e) => setInputEmail(e.target.value)}/>
                </div>

                <div className="flex flex-row items-center">
                    <label className='w-1/4 mr-5'>Password</label>
                    <input className='w-3/4 rounded-lg'
                           key="input_pw"
                           type="password"
                           value={inputPassword}
                           onChange={(e) => setInputPassword(e.target.value)}/>
                </div>

                <button type="submit"
                        className="bg-theme py-2 rounded-md shadow-md cursor-pointer hover:shadow-lg"
                        onClick={tryLogin}>
                    Signin
                </button>
                {(Object.keys(error).length !== 0 && error.constructor === Object) &&
                    <div className=""
                         onClick={() => clearError()}>
                        {error.text}
                    </div>
                }
            </form>
        </div>
    )
}

export default Login