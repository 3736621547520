// const fs = require('fs');
// var path = require('path');



// minify = false, means human readable, set this to true to minify the file for deployment
const minify = false;

// specify found keys to remove
let keysToRemove = [
    "Color",
    "Size",
    "ZIndex",
    "Position",
    "StageDirections",
    "ShortId",
    "TechnicalName",
    "Label",
    "ObjectDefinitions",
    "Hierarchy",
    "IsDefaultPackage",
    "Vertices",
    "Transform",
    "ShapeType",
    "OutlineColor",
    "OriginalSource",
    "ScriptMethods",
    "ViewBox"
];

export const cleanJsonData = (jsonData, min = false, keys = null, originalFile) => {
    
    if (keys != null) {
        keysToRemove = keys.split(',')
    }
    
    const data = {
        originalData: JSON.stringify(jsonData, null, 2),
        cleanedData: "",
        originalLineCount: 0,
        originalFile: originalFile,
        cleanedLineCount: 0,
        cleanedFileSize: 0,
        savingsCount: 0,
        savingsPercentage: "0%"
    }
    
    try 
    {
        data.originalLineCount = data.originalData.split(/\r\n|\r|\n/).length;

        if (!min)
            data.cleanedData = JSON.stringify(jsonData, replacer, 2);
        else
            data.cleanedData = JSON.stringify(jsonData, replacer);

        data.cleanedLineCount = data.cleanedData.split(/\r\n|\r|\n/).length;

        data.savingsCount = (data.originalLineCount - data.cleanedLineCount);
    }
    catch (error)
    {
        console.error(error);
    }

    return data;
}

function replacer(key, value) {
    if (keysToRemove.includes(key)) {
        return undefined;
    }
    return value;
}