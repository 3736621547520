import React from 'react'

import ProjectsTable from "./Tables/ProjectsTable";
import UsersTable from "./Tables/UsersTable";
import InfoPanel from "./InfoPanel";

const Admin = () => {

    return (
        <div className="max-w-screen-2xl mx-auto p-2 container flex flex-col space-y-5 mt-8">
            <ProjectsTable/>
            <UsersTable/>
            <InfoPanel/>
        </div>
    )
}

export default Admin