import React, {useEffect, useMemo} from 'react'
import {useAuth} from "../../Auth/auth";
import {useNavigate} from "react-router-dom";
import UseFetch from '../../Hooks/useFetch'
import LoadingOverlay from "../LoadingOverlay";
import MaterialTable from "material-table";
import PatchedPagination from "../../Misc/PatchedPagination";
import tableIcons from "../../Data/tableIcons";

const ProjectsTable = () => {

    let navigate = useNavigate();
    const auth = useAuth();
    useEffect(async () => {

        const validToken = await auth.isSignedIn();
        if (!validToken)
            navigate('/', {replace: true});
    }, [])

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${auth.tokenData.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    function updateProject(oldTitle, newData) {

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${auth.tokenData.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                projectTitle: oldTitle,
                projectPhase: newData.project_phase,
                domainName: newData.domain_name
            })
        };
        const host = process.env.REACT_APP_SERVER_HOST;
        const port = process.env.REACT_APP_API_PORT;

        fetch(`${host}:${port}/api/projects`, requestOptions)
            .then(data => {
                console.log(data)
                setRefresh(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const {data, setRefresh, loading, error} = UseFetch({
        url: "/api/projects",
        options: requestOptions,
    });
    const fetchedData = useMemo(() => [...data], [data]);

    if (loading || !data) return <div className="rounded-xl overflow-hidden shadow-lx"><LoadingOverlay/></div>

    return (
        <MaterialTable
            components={{
                Pagination: PatchedPagination,
            }}
            options={{
                exportButton: true,
                exportAllData: true,
                exportFileName: `PersonaProjects_${new Date(Date.now()).toLocaleString('nl')}`,

                actionsColumnIndex: -1,

                paging: true,
                pageSize: 15,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [10, 15, 20, 50],

                tableLayout: 'auto',
            }}
            icons={tableIcons}
            title="Projects"
            columns={[
                {
                    title: 'ID', field: 'id',
                    cellStyle: {width: '0px',},
                    headerStyle: {width: '0px',},
                    editable: 'never'
                },
                {
                    title: 'Project title',
                    field: 'project_title',
                    editable: 'onAdd'
                },
                {
                    title: 'Current project phase',
                    field: 'project_phase',
                },
                {
                    title: 'Domain name',
                    field: 'domain_name',
                    editable: 'always'
                },
                {
                    title: 'Created at',
                    field: 'created_at',
                    render: rowData => (
                        <div className="">{new Date(rowData.created_at).toLocaleString('nl')}</div>
                    ),
                    cellStyle: {width: '200px',},
                    headerStyle: {width: '200px',},
                    editable: 'never'
                },
                {
                    title: 'Last updated at',
                    field: 'updated_at',
                    render: rowData => (
                        <div className="">{new Date(rowData.updated_at).toLocaleString('nl')}</div>
                    ),
                    cellStyle: {width: '200px',},
                    headerStyle: {width: '200px',},
                    editable: 'never'
                }
            ]}
            data={fetchedData}

            editable={{
                onRowAdd: newData =>
                    new Promise(async (resolve, reject) => {
                        await updateProject(newData.project_title, newData);
                        resolve();
                    }),

                onRowUpdate: (newData, oldData) =>
                    new Promise(async (resolve, reject) => {
                        await updateProject(oldData.project_title, newData);
                        resolve();
                    }),
            }}
        />
    )
}

export default ProjectsTable