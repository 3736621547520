import create from 'zustand';
import {v4 as uuidv4} from 'uuid';
import jwt_decode from "jwt-decode";

const getLocalStorage = (key) => JSON.parse(window.localStorage.getItem(key));
const setLocalStorage = (key, value) => window.localStorage.setItem(key, JSON.stringify(value));
//const deleteLocalStorage = (key) => window.localStorage.removeItem(key);

export const useUserStore = create((set, get) => ({

    user: getLocalStorage("user") || {
        prompted: false,
        allowedCookies: false,
        uuid: "",
    },
    tokenData: null,
    setTokenData: (tokenData) => {

        set((state) => {
                state.tokenData = tokenData;
                //console.log('tokendata saved in memory!', state.tokenData)
            }
        )
    },
    clearUser: () => {

        set(state => {
            state.tokenData = null;
            state.user = (({prompted, allowedCookies, uuid}) => ({prompted, allowedCookies, uuid}))(state.user);
            setLocalStorage('user', state.user);
        });
    },
    setCookieAllowed: (cookiesAllowed) => set(state => {
        state.user = {
            ...state.user,
            allowedCookies: cookiesAllowed,
            prompted: true,
            uuid: uuidv4()
        }
        setLocalStorage('user', state.user);
    }),
    isTokenValid: () => {

        const tokenData = get().tokenData;

        if (!tokenData) {
            return false;
        }

        try {
            const decoded = jwt_decode(tokenData.token);

            if (decoded.exp * 1000 < Date.now()) {
                return false;
            }

            if (decoded != undefined)
                return true;
            else
                return false;
        } catch (err) {
            console.log(err)
            return false;
        }
    },

    error: {},
    setError: (text, severity) => {
        set((state) => {
            state.error.text = text;
            state.error.severity = severity;
        })
    },
    clearError: () => set(state => state.error = {})
}))

export default useUserStore;