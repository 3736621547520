import React from 'react'
import {ReactComponent as Logo} from "../Images/personunknown_logo_simplified.svg";

const LoadingOverlay = () => {
    return (
        <div className="bg-white flex flex-col w-full">
            <div className="relative bg-white m-auto w-52 h-52 rounded-lg shadow-xl overflow-hidden">
                <div
                    className="bg-gradient-to-b from-white to-theme-light h-80 w-80 absolute -top-14 -left-14 animate-spin"/>
                <Logo className="w-full h-full absolute p-3 top-0 left-0 h-50 w-50"/>
            </div>

        </div>
    )
}

export default LoadingOverlay